import http from "../http-common";

const membership = async (id, recaptcha) => {
  return await http
    .get("/verify/membership", {
      params: { id },
      headers: { recaptcha },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const ticket = async (id, recaptcha) => {
  return await http
    .get("/verify/ticket", {
      params: { id }, 
      headers: { recaptcha },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const cashout = async (id, recaptcha) => {
  return await http
    .get("/verify/cashout", {
      params: { id },
      headers: { recaptcha },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  membership,
  ticket,
  cashout,
};
